header {
    display: flex;
    height: 48px;
    background-color: #001529;
    align-items: center;
    width: auto;
    padding: 0 16px;
    justify-content: space-between;
}

.header-toolbar {
    width: auto;
    align-items: center;
    display: flex;
    gap: 24px;
}

.header-toolbar-right {
    width: 18px;
    height: 18px;
}

.header-toolbar-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-ico {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    object-fit: cover;
}

.user-name {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
}

.anticon-global {
    cursor: pointer;
    width: 18px;
    height: 18px;
    align-items: center;
}

.anticon-global svg {
    fill: white;
}
.mobile-menu{
    display: none;
}

@media screen and (max-width: 680px) {
    header {
        padding: 0 12px;
    }
    .mobile-menu{
        display: flex;
    }

    .header-toolbar {
        width: auto;
        align-items: center;
        display: flex;
        gap: 14px;
    }
    .logout-ico{
        display: none;
    }
    .mobile-modal{
        width: 100vw!important;
    }
    .ant-modal-body a{
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #001529;
        padding: 20px 0;
    }

}
aside {
    box-shadow: 0px 2px 8px 0px #00000026;
}

.ant-menu {
    border-inline-end: unset !important;
}


.ant-menu-item-selected {
    background: "#E6F7FF";
    box-shadow: -3px 0px 0px 0px #03B3B4 inset;

}

.ant-menu-item-selected span {

    color: #03B3B4;
}
.ant-layout-sider-children{
    position: relative;
}
.ant-menu-item span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.ant-menu-submenu-title {
    margin: 4px 0 0 0 !important;
    width: 100% !important;
}

.ant-layout-sider-trigger {
    width: 100%;
    position: absolute!important;
    max-width: 192px;
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
    box-shadow: 0px 1px 0px 0px #F0F0F0 inset;
}

.new-godo {
    background-color: #03B3B4;
    width: 100%;
    height: 40px;
    box-shadow: 0px 2px 0px 0px #0000000B;
    border-radius: 2px;
    text-decoration: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.new-godo:hover{
    color: white;
}

.anticon-edit svg {
    fill: white;
}
@media screen and (max-width: 680px){
    .ant-layout-sider{
        display: none;
    }
    .ant-menu-item{
        padding-inline: calc(50% - 3px - 4px) !important;
    }
}
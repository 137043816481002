.dashboard-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 0 24px;
    height: 100px;
}

.left {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
}

.left img {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    object-fit: cover;
}

.left .text {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.left .text h1 {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #000000D9;
}

.left .text h2 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
}

.right {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
}

.right .item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.right .item p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
}

.right .item span {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: right;
    color: #03B3B4;
}

.right .item .suflex {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000D9;

}

.right .item .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

@media screen and (max-width: 680px) {
    .dashboard-head {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 4px 12px;
        gap: 8px;
        height: auto;
    }

    .left {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
    }

    .left img {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        object-fit: cover;
    }

    .left .text {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .left .text h1 {
        font-size: 14px;
        line-height: 20px;
    }

    .left .text h2 {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    .right {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
    }

    .right .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    .right .item p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #00000073;
    }

    .right .item span {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: right;
        color: #03B3B4;
    }

    .right .item .suflex {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #000000D9;

    }

    .right .item .wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
    }
}
.dashboard-stats {
    display: flex;
    padding: 24px;
    flex-direction: row;
    gap: 24px;
}

.dashboard-stats-item {
    width: calc(25% - 18px);
    height: 151px;
    background: #FFFFFF;
    border: 1px solid #0000000F
}

.stats-item {
    padding: 16px 24px 24px 24px;
}

.stats-item-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
}

.stats-item-title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
}

.stats-item-icon {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #03B3B4;
    display: flex;
    justify-content: center;
    align-items: center;

}

.stats-item-icon svg {
    fill: white;
}

.stats-item-value {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    color: #000000D9;
    padding-bottom: 5px;
}


.stats-item-details {
    display: flex;
    flex-direction: column;
    padding-bottom: 9px;
}

.stats-item-bottom {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: #000000D9;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}

@media screen and (max-width: 680px) {
    .dashboard-stats {
        display: flex;
        padding: 12px;
        flex-direction: column;
        gap: 12px;
    }

    .dashboard-stats-item {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border: 1px solid #0000000F
    }

    .stats-item {
        padding: 12px 12px 12px 12px;
    }

    .stats-item-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
    }

    .stats-item-title {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #00000073;
    }

    .stats-item-icon {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: #03B3B4;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .stats-item-icon svg {
        fill: white;
    }
    .stats-item-icon svg path{
        width: 100%;
    }

    .stats-item-value {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
        padding-bottom: 5px;
    }


    .stats-item-details {
        display: flex;
        flex-direction: column;
        padding-bottom: 9px;
    }

    .stats-item-bottom {
        display: flex;
        flex-direction: row;
        gap: 8px;
        color: #000000D9;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }
}
.loader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #03B3B4;
    z-index: 100000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loader-wrapper svg{
    transition: opacity 0.4s ease-in-out;
    animation: opacity 1s infinite ease-in-out;
}
@keyframes opacity {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.dashboard-total {
    height: auto;
    padding: 0 24px 24px 24px;
    width: calc(100% - 48px);
    margin: 0 auto;
    background-color: white;
}

.dashboard-total-head {
    padding: 24px 24px 24px 0;
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: row;
}

.dashboard-total-head .title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000D9;
}

.dashboard-total-head .time {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    text-align: center;
    color: #000000D9;
    border: 2px solid #0000000F;
    height: 36px;
    padding: 0 14px;
}

.dashboard-cards {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}

.dashboard-cards-item {
    flex: 1;
    border-radius: 6px;
    position: relative;
    display: flex;
    width: auto;
    min-width: calc(20% - 4px);
    flex-direction: column;
    padding: 48px 20px;
    gap: 32px;
    height: 174px;
    overflow: hidden;
}

.dashboard-tottal-card-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
}

.dashboard-tottal-card-value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
}

.top-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 37px;
    height: 19px;
    z-index: 1;
    border-radius: 0 6px 6px 0;
}

.bottom-element {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 44px;
    height: 41px;
    z-index: 1;
    border-radius: 6px 0 6px 0;
}

@media screen and (max-width: 680px) {
    .dashboard-total {
        height: auto;
        padding: 0 12px 12px 12px;
        width: calc(100% - 24px);
        margin: 0 auto;
        background-color: white;
    }

    .dashboard-total-head {
        padding: 12px 12px 12px 0;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-direction: row;
    }

    .dashboard-total-head .title {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
    }

    .dashboard-total-head .time {
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 500;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        text-align: center;
        color: #000000D9;
        border: 2px solid #0000000F;
        height: 24px;
        padding: 0 7px;
    }

    .dashboard-cards {
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;
    }

    .dashboard-cards-item {
        flex: 1;
        border-radius: 6px;
        position: relative;
        display: flex;
        width: auto;
        min-width: calc(20% - 4px);
        flex-direction: column;
        padding: 24px 10px;
        gap: 16px;
        height: 174px;
        overflow: hidden;
    }

    .dashboard-tottal-card-title {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #FFFFFF;
    }

    .dashboard-tottal-card-value {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #FFFFFF;
    }

    .top-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 37px;
        height: 19px;
        z-index: 1;
        border-radius: 0 6px 6px 0;
    }

    .bottom-element {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 44px;
        height: 41px;
        z-index: 1;
        border-radius: 6px 0 6px 0;
    }
}
.tasks-wrapper {
    width: calc(100% - 48px);
    display: flex;
    margin: 24px;
    padding: 24px;
    flex-direction: column;
    background-color: white;
}

.tasks-wrapper-head {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.tasks-title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #000000D9;
}

.tasks-wrapper-head a {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: center;
    border: 1px solid #03B3B4;
    box-shadow: 0px 2px 0px 0px #0000000B;
    background-color: #03B3B4;
    gap: 8px;
    opacity: 0;
    color: white;
    text-decoration: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    border-radius: 2px;

}

.ant-table-wrapper {
    margin-top: 16px;
}

.ant-table-cell a:first-of-type {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #03B3B4;
}

.ant-table-cell {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000D9;
}

.ant-table-thead tr th {
    background-color: #FAFAFA !important;
    border-start-start-radius: 0 !important;
    border-start-end-radius: 0 !important;
}

th {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 22px;
    text-align: left;
    color: #000000D9;
}

.ant-pagination-item-active a {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #03B3B4 !important;
}

.ant-pagination-item-active {
    border-color: #03B3B4 !important;
}

.ant-pagination li {
    border: 1px solid #D9D9D9;
    border-radius: 2px;
}

.ant-pagination-item-link {
    border-radius: 2px !important;
}

.table-info {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #000000D9;
}

.ant-select-selection-search input {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000D9;
}

.select-per .ant-select-selection-item {
    color: #000000D9;
}

.select-per {
    width: 93px !important;
}

.select-per .ant-select-selection-item {
    padding-inline-end: 0 !important;
}

.modall-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.modall-wrapper .modall-left {
    display: flex;
    gap: 8px;
    flex-direction: column;
    font-size: 14px;
}

.modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.modall-wrapper .modall-right {
    font-size: 14px;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.ant-modal {
    width: 400px !important;
}

.modal-names {
    font-size: 16px;
    padding-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid black;
}

.ant-table-column-sorters {
    align-items: self-start !important;
    justify-content: start!important;

}

.ant-table-column-has-sorters {
    text-align: end !important;
}
.ant-table-cell{
    max-width: 200px;
    padding: 13px 13px!important;
}

@media screen and (max-width: 680px) {
    .tasks-wrapper {
        width: max-content;
        display: flex;
        margin: 12px;
        padding: 12px;
        flex-direction: column;
        background-color: white;
    }

    .tasks-wrapper-head {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .tasks-title {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
    }

    .tasks-wrapper-head a {
        width: auto;
        height: 24px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        justify-content: center;
        border: 1px solid #03B3B4;
        box-shadow: 0px 2px 0px 0px #0000000B;
        background-color: #03B3B4;
        gap: 8px;
        opacity: 0;
        color: white;
        text-decoration: none;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        border-radius: 2px;

    }

    .ant-table-wrapper {
        margin-top: 8px;
    }

    .ant-table-cell a:first-of-type {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #03B3B4;
    }

    .ant-table-cell {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
    }

    .ant-table-thead tr th {
        background-color: #FAFAFA !important;
        border-start-start-radius: 0 !important;
        border-start-end-radius: 0 !important;
    }

    th {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500 !important;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
    }

    .ant-pagination-item-active a {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #03B3B4 !important;
    }

    .ant-pagination-item-active {
        border-color: #03B3B4 !important;
    }

    .ant-pagination li {
        border: 1px solid #D9D9D9;
        border-radius: 2px;
    }

    .ant-pagination-item-link {
        border-radius: 2px !important;
    }

    .table-info {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #000000D9;
    }

    .ant-select-selection-search input {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #000000D9;
    }

    .select-per .ant-select-selection-item {
        color: #000000D9;
    }

    .select-per {
        width: 93px !important;
    }

    .select-per .ant-select-selection-item {
        padding-inline-end: 0 !important;
    }

    .modall-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .modall-wrapper .modall-left {
        display: flex;
        gap: 8px;
        flex-direction: column;
        font-size: 12px;
    }

    .modal-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .modall-wrapper .modall-right {
        font-size: 16px;
        display: flex;
        gap: 8px;
        flex-direction: column;
    }

    .ant-modal {
        width: 300px !important;
    }

    .modal-names {
        font-size: 20px;
        padding-top: 20px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid black;
    }

    .ant-table-column-sorters {
        align-items: self-start !important;
    }

    .ant-table-column-has-sorters {
        text-align: end !important;
    }
}
.category-table{
    text-align: center;
    border-radius: 21px;
    padding: 0 4px;
}
.delete-title{
    font-family: Roboto;
}
.ant-table-column-title{
    flex: unset!important;
}
.login-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.logo-wrapper {
    object-fit: cover;
}

.ant-tabs-tab-btn {
    color: #000000D9 !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #03B3B4 !important;
}

.ant-tabs-ink-bar {
    background-color: #03B3B4 !important;
}

.restore-wrapper {
    width: 368px;
}

.login-form {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

.ant-input-affix-wrapper {
    padding: 0 13px;
    font-family: Roboto, sans-serif;
    border: 1px solid #D9D9D9;
    font-size: 16px;
    font-weight: 400;
    box-shadow: none;
    line-height: 24px;
    text-align: left;
    border-radius: 0;
}

.login-form input {
    padding: 0 13px;
    border-radius: 0;
    width: 100%;
    height: 40px;
    border: 1px solid #D9D9D9;
    background-color: white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}

.submitbtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 114px;
    height: 40px;
    padding: 0;
    gap: 10px;
    box-shadow: none;
    border-radius: 2px;
    background-color: #03B3B4;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    border-color: unset;
    border: none;
}

.login-error {
    margin-top: -20px;
    justify-content: flex-start;
    display: flex;
    color: red;
    width: 100%;
    font-family: Roboto,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}
.one-godo-wrapper {
    display: flex;
    margin: 24px;
    padding: 32px;
    background-color: white;
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    align-items: start;
}

.one-godo-left {
    max-width: 601px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.one-godo-left-head {
    display: flex;
    align-items: center;
    gap: 16px;
}

.one-godo-left-head span {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000D9;
}

.one-godo-left-content {
    display: flex;
    flex-direction: column;
}

.description {
    display: flex;
    flex-direction: column;
    padding-left: 30px;

}

.description-title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
    padding-bottom: 8px;
}

.description-content {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #333333;
    padding-bottom: 20px;

}

.one-godo-left-content .badge {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    height: 30px;
    border-radius: 21px;
    padding: 4px 7px;
    width: fit-content;
    background: #6BE095;
}

.description-status {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
}

.description-status-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.description-status-item-title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
}

.description-status-item-desc {
    font-family: Helvetica Neue;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.03px;
    text-align: left;
    color: #333333;
}

.one-godo-right {
    max-width: 320px;
    width: 320px;
}

.one-godo-right img {
    width: 100%;
}

.mySwiper2 img {
    height: 102px;
    object-fit: cover;
}

.mySwiper1 {
    margin-bottom: 6px;
}

.mySwiper1 img {
    height: 265px;
    object-fit: cover;
}

.swiper-wrapper {
    width: 320px;
}

.desc-wr {
    display: flex;
    flex-direction: row;
    gap: 39px;
    align-items: center;
    width: 100%;
}

.status-wr {
    display: flex;
    gap: 12px;
}

.status-wr img {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    object-fit: cover;
}

.status-user-info {
    gap: 4px;
    display: flex;
    flex-direction: column;
}

.status-user-info p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #000000D9;
}

.status-user-info span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #000000D9;
}

.stat-badge {
    display: flex;
    gap: 8px;
    align-items: center;
}

.badges {
    width: 6px;
    height: 6px;
    border-radius: 100%;
}

.doing .badges {
    background: #03B3B4;
}

.nots .badges {
    background: #FF4D4F;
}

.done .badges {
    background: #D9D9D9;
}

.flex-item {
    gap: 39px;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 680px) {
    .one-godo-wrapper {
        margin: 12px;
        padding: 16px;
        width: auto;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
    }

    .one-godo-left {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 12px;
    }

    .one-godo-left-head {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .one-godo-left-head span {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
    }

    .one-godo-left-content {
        display: flex;
        flex-direction: column;
    }

    .description {
        display: flex;
        flex-direction: column;
        padding-left: 0;

    }

    .description-title {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #00000073;
        padding-bottom: 4px;
    }

    .description-content {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #333333;
        padding-bottom: 10px;

    }

    .one-godo-left-content .badge {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #FFFFFF;
        height: 30px;
        border-radius: 21px;
        padding: 4px 7px;
        width: fit-content;
        background: #6BE095;
    }

    .description-status {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 8px;
    }

    .description-status-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .description-status-item-title {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #00000073;
    }

    .description-status-item-desc {
        font-family: Helvetica Neue;
        font-size: 12px;
        font-weight: 400;
        line-height: 17.03px;
        text-align: left;
        color: #333333;
    }

    .one-godo-right {
        max-width: 100%;
        width: 100%;
    }

    .one-godo-right img {
        width: 100%;
    }

    .mySwiper2 img {
        height: 80px;
        object-fit: cover;
    }

    .mySwiper1 {
        margin-bottom: 6px;
    }

    .mySwiper1 img {
        height: 200px;
        object-fit: cover;
    }

    .swiper-wrapper {
        width: 100%;
    }

    .desc-wr {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        width: 100%;
    }

    .status-wr {
        display: flex;
        gap: 12px;
    }

    .status-wr img {
        width: 44px;
        height: 44px;
        border-radius: 100%;
        object-fit: cover;
    }

    .status-user-info {
        gap: 4px;
        display: flex;
        flex-direction: column;
    }

    .status-user-info p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
    }

    .status-user-info span {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #000000D9;
    }

    .stat-badge {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .badges {
        width: 6px;
        height: 6px;
        border-radius: 100%;
    }

    .doing .badges {
        background: #03B3B4;
    }

    .nots .badges {
        background: #FF4D4F;
    }

    .done .badges {
        background: #D9D9D9;
    }

    .flex-item {
        gap: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .ant-picker-dropdown {
        inset: 266px auto auto 52px !important;
    }

    .ant-picker-datetime-panel {
        flex-direction: column;
    }

    .ant-picker-panel-container {
        scale: 0.8;
    }
}
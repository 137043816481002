.site-layout-background {
    overflow: auto !important;
    height: 97vh !important;
}

body {
    overflow: hidden;
}

@media screen and (max-width: 680px) {
    .site-layout-background {
        overflow: auto !important;
        height: 90dvh !important;
    }

    .ant-layout-sider-collapsed {
        flex: 0 0 40px !important;
        max-width: 40px !important;
        min-width: 40px !important;
        width: 40px !important;
    }

    .ant-layout-sider {
        flex: 0 0 140px;
        max-width: 140px;
        min-width: 140px;
        width: 140px;
    }
    .ant-picker-header-view{
        display: none;
    }

}

.ant-menu-item-selected {
    background-color: #e6f4ff !important;
}

input:focus-visible {
    outline: none;
}

.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 65vw;
    height: fit-content;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    z-index: 1000;
}
.Modal:focus-visible{
    outline: none;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.mySwiper3 .swiper-slide img {
    width: 100%;
    height: 65vw;
    max-height: 80vh;
    object-fit: contain;
}
.swiper-button-next, .swiper-button-prev {
    color: black!important;
    scale: 0.8;
}
.new-godo-head {
    width: 100%;
    height: 92px;
    background-color: white;
    padding: 12px 24px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.new-godo-title {
    color: #000000D9;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
}

.new-godo-sub {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
}

.new-godo-forms-wrapper {
    height: auto;
    padding: 24px;
    width: 100%;
}

.new-godo-form {
    width: 549px;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    margin: 0 auto;
    width: fit-content;
    gap: 24px;
}


.ant-input-affix-wrapper {
    padding: 0 11px !important;
    font-family: Roboto, sans-serif;
    border: 1px solid #D9D9D9;
    font-size: 14px !important;
    font-weight: 400;
    box-shadow: none;
    line-height: 24px;
    text-align: left;
    border-radius: 0;
}

.new-godo-form input {
    padding: 0 11px;
    border-radius: 0;
    max-width: 484px;
    width: 484px;
    height: 32px;
    border: 1px solid #D9D9D9;
    background-color: white;
    font-family: Roboto, sans-serif;
    font-size: 14px!important;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000D9 !important;

}

.new-godo-form input::placeholder {
    color: #00000040;
}

.submitbtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 114px;
    height: 40px;
    padding: 0;
    gap: 10px;
    box-shadow: none;
    border-radius: 2px;
    background-color: #03B3B4;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    border-color: unset;
    border: none;
}

.error {
    margin-top: 5px;
    margin-left: 90px;
    justify-content: flex-start;
    display: flex;
    color: red;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.input-title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: right;
    color: #000000D9;
    padding-right: 8px;
}

.input-wrapp {
    width: 100%;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: flex-end;
}

.ant-picker {
    border-radius: 0;
    max-width: 484px;
    width: 484px;
    padding: 4px 11px 4px 11px !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #03b3b4 !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #03b3b4 !important;
}

.ant-btn-primary {
    background-color: #03b3b4 !important;
    color: white;
}

.ant-picker-time-panel {
    padding-left: 2px;
    box-shadow: 0px 2px 8px 0px #00000026;
}

.ant-picker-month-panel {
    width: 484px !important;

}

.ant-picker-month-panel .ant-picker-content {
    width: 252px !important;
    margin: 8px auto;
}

.ant-picker-year-panel {
    width: 484px !important;
}

.ant-picker-decade-btn,
.ant-picker-year-btn,
.ant-picker-month-btn {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 22px;
    text-align: center;

}

.ant-select-selector {
    border-radius: 2px !important;
}

.ant-select-selection-item {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000D9;
}

.photo-list {
    max-width: 440px !important;
    min-height: 32px;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 0;
    width: 440px !important;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 4px;
}

.photo-item {
    border: 1px solid #F0F0F0;
    background-color: #F5F5F5;
    display: flex;
    border-radius: 2px;
    align-items: center;
    gap: 4px;
    padding: 2px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #000000D9;
}

.ant-upload-wrapper {
    height: 32px !important;
}

.delete-icon {
    cursor: pointer;
}

.button-wr {
    display: flex;
    justify-content: end;
}

.wr-wr {
    max-width: 484px;
    width: 100%;
}

.create-task {
    /*width: 107px;*/
    height: 32px;
    padding: 5px 16px;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    background-color: #03B3B4;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.wrapp {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ant-select-arrow {
    width: 14px !important;
    height: 14px !important;
}

.inp {
    display: flex;
    align-items: center;
}

.new_godo_error {
    margin-top: 5px;
    width: 484px;
}

@media screen and (max-width: 680px) {
    .new-godo-head {
        width: 100%;
        height: auto;
        background-color: white;
        padding: 12px 12px 12px 12px;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .new-godo-title {
        color: #000000D9;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
    }

    .new-godo-sub {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #00000073;
    }

    .new-godo-forms-wrapper {
        height: auto;
        padding: 12px;
        width: 100%;
    }

    .new-godo-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 12px 0;
        margin: 0 auto;
        gap: 12px;
    }


    .ant-input-affix-wrapper {
        width: 100% !important;
        padding: 0 11px !important;
        font-family: Roboto, sans-serif;
        border: 1px solid #D9D9D9;
        font-size: 12px !important;
        font-weight: 400;
        box-shadow: none;
        line-height: 24px;
        text-align: left;
        border-radius: 0;
    }

    .new-godo-form input {
        padding: 0 11px;
        border-radius: 0;
        max-width: 100%;
        width: 100%;
        height: 32px;
        border: 1px solid #D9D9D9;
        background-color: white;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #000000D9 !important;

    }

    .new-godo-form input::placeholder {
        color: #00000040;
    }

    .submitbtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 114px;
        height: 40px;
        padding: 0;
        gap: 10px;
        box-shadow: none;
        border-radius: 2px;
        background-color: #03B3B4;
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        border-color: unset;
        border: none;
    }

    .error {
        margin-top: 5px;
        margin-left: 90px;
        justify-content: flex-start;
        display: flex;
        color: red;
        width: 100%;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
    }

    .input-title {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #000000D9;
        margin: 0;
        padding-right: 8px;
        width: 100%;
    }

    .input-wrapp {
        width: 100%;
        display: flex;
        align-items: end;
        flex-direction: column;
        justify-content: flex-end;
    }

    .ant-picker {
        border-radius: 0;
        max-width: 100%;
        width: 100%;
        padding: 4px 11px 4px 11px !important;
    }

    .inp fieldset {
        width: 100%;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
        background-color: #03b3b4 !important;
    }

    .ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: #03b3b4 !important;
    }

    .ant-btn-primary {
        background-color: #03b3b4 !important;
        color: white;
    }

    .ant-picker-time-panel {
        padding-left: 2px;
        box-shadow: 0px 2px 8px 0px #00000026;
    }

    .ant-picker-month-panel {
        width: 100% !important;

    }

    .ant-picker-month-panel .ant-picker-content {
        width: 100% !important;
        margin: 8px auto;
    }

    .ant-picker-year-panel {
        width: 484px !important;
    }

    .ant-picker-decade-btn,
    .ant-picker-year-btn,
    .ant-picker-month-btn {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 22px;
        text-align: center;

    }

    .ant-select-selector {
        border-radius: 2px !important;
    }

    .ant-select-selection-item {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #000000D9;
    }

    .photo-list {
        max-width: 90% !important;
        min-height: 32px;
        flex-wrap: wrap;
        background-color: white;
        border-radius: 0;
        width: 440px !important;
        border: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 4px;
        margin-right: 34px;
    }

    .photo-item {
        border: 1px solid #F0F0F0;
        background-color: #F5F5F5;
        display: flex;
        border-radius: 2px;
        align-items: center;
        gap: 4px;
        padding: 2px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #000000D9;
    }

    .ant-upload-wrapper {
        height: 32px !important;
    }

    .delete-icon {
        cursor: pointer;
    }

    .button-wr {
        display: flex;
        justify-content: end;
    }

    .wr-wr {
        max-width: 484px;
        width: 100%;
    }

    .create-task {
        /*width: 107px;*/
        height: 32px;
        padding: 5px 16px;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        background-color: #03B3B4;
        box-shadow: none;
        border: none;
        cursor: pointer;
    }

    .wrapp {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    .ant-select-arrow {
        width: 14px !important;
        height: 14px !important;
    }

    .inp {
        width: 100%;
        display: flex;
        gap: 0;
        align-items: center;
        flex-direction: column;
    }

    .new_godo_error {
        margin-top: 5px;
        width: 100%;
    }

    .ant-select {
        width: 100% !important;
    }
    .add-svg{
        position: absolute;
        margin-top: 34px;
        right: 12px;
    }
}